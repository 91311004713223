import React, {useRef, useCallback, useMemo, useEffect} from 'react'
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { Button, Form, InputGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import $ from "jquery";
import { toast } from 'react-toastify';
import { debounce } from 'lodash'
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import {formatPlace} from "../../util/functions";

function MapSelector(props) {
  const refSearchInput = useRef(null);
  const refMap = useRef(null);
  const refMarker = useRef(null);
  const refPlacesService = useRef(null);

  const setCoords = (coords) => {
    props.updateCoords(coords);
  }

  const onMapUnmount = useCallback(function callback(map) {
    refMap.current=null;
    refSearchInput.current=null;
    refMarker.current=null;
    refPlacesService.current=null;
  }, []);

  const onMapLoad = useCallback(function callback(map) {
    //creating the marker that will hover over the center of the map
    refMarker.current = new window.google.maps.Marker({
      position:  map.getCenter(),
      map: map,
      title: 'Marker at center of map',
      draggable: true
    });

    //user can drag Map OR the marker
    refMarker.current.addListener('drag', (event) => {
      let coords = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
      setCoords(coords);
    });

    refMarker.current.addListener('dragend', (event) => {
      let coords = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
      setCoords(coords);
    });

    //creating the service that will look up address info after user searches
    refPlacesService.current = new window.google.maps.places.PlacesService(map);

    refMap.current=map;

    if(props.mapReadyToLoad) {
      if(props.initialAddress) {
        // Set map location by searching using the provided site address
        locationSearch(props.initialAddress);
      }
      else if(!props.initialLat || !props.initialLng) {
        //if no lat or lng value, we assume map is being loaded for first time and user has not chosen a location.
        //therefore, center map on current location.
        //Important: props.mapReadyToLoad is important here. This map component will initially load before the parent CreateProjectRequest
        //  finishes pulling initialLat and initialLng from database. We don't want to start centering on current location before we know
        //  that it is necessary. (Loading current location takes a second or two. Sometimes it will start getting curr loc., then database
        //  returns user selected lat/lng, and then current loc finishes and moves map away from user's chosen location.)
        centerMapOnCurrentLocation();
      }
    }
  }, [props.mapReadyToLoad]);

  const onAutocompleteLoad = useCallback(function callback(ac) {
    //work-around to hide pac-container because it doesn't move correctly when modal scrolls
    var modal = $('.modal-body');
    if (modal && modal.length > 0) {
      modal[0].addEventListener('scroll', () => {
        var input = document.getElementById("addressSearchInputId");
        if (input) {
          input.blur();
        }
      })
    }
  }, [])

  const centerMapOnCurrentLocation = (userInitiated) => {
    // check if the Geolocation API is supported
    if (!navigator.geolocation) {
      //browser doesn't support geolocation. return
      console.log("User browser doesn't support geolocation");
      toast.warn("Your browser doesn't support geolocation");
      return;
    }

    console.log('Attempting to center map on current location');

    navigator.geolocation.getCurrentPosition((position) => {
      const {
        latitude,
        longitude
      } = position.coords;

      refMap.current.setCenter({
        lat: Number(latitude),
        lng: Number(longitude)
      })

      refMap.current.setZoom(19);
    }, 
    (error)=>{
      console.log('Location error occurred: ', error);
      if(userInitiated && error.message){
        toast.warn(error.message);
      }
    });
  }

  const onMapCenterChanged = useCallback(() => {
    if (!refMap || !refMap.current) return;
    if (!refMarker || !refMarker.current) return;
    const center = refMap.current.getCenter();
    refMarker.current.setPosition(center); //this line might not be needed
    let coords = {
      lat: center.lat(),
      lng: center.lng(),
    }
    setCoords(coords);
  }, [refMap]);

  const debouncedInfoToast = useRef(debounce(msg=>toast.info(msg, {autoClose: 30000}), 300)).current; //used to prevent double-calling of toast method when clicking "Enter" after searching for bad address

  const locationSearch = (initialAddress) => {
    const searchText = initialAddress ? initialAddress : refSearchInput.current.value;
    if(!searchText) {
      refSearchInput.current.focus();
      props.onPlaceSearchedInMap(null);
      return;
    }

    const request = {
      query: searchText,
      fields: ["geometry","place_id"],
    };

    const getDetailsFromPlaces_Callback = (place, status) => {
      if(status !== window.google.maps.places.PlacesServiceStatus.OK){
        console.log('Error occurred while searching map. code: x222');
        debouncedInfoToast("Unable to find results for search")
        props.onPlaceSearchedInMap(null);
        return;
      }

      //create a nice object to return back to parent object
      let formattedResultObject = formatPlace(place)

      if(formattedResultObject.streetAddress){
        refMap.current.setZoom(19);
      }else{
        refMap.current.setZoom(12);
      }

      props.onPlaceSearchedInMap(formattedResultObject);
    }

    const findPlaceFromQuery_Callback = (results, status) => {
      if(!(status===window.google.maps.places.PlacesServiceStatus.OK) || !results){
        console.log('Unable to find location. code: x111');
        debouncedInfoToast("Unable to find address. Use map controls to move pin to construction location.");
        props.onPlaceSearchedInMap(null);
        return;
      }

      refMap.current.setCenter(results[0].geometry.location);

      refPlacesService.current.getDetails({ placeId: results[0].place_id}, getDetailsFromPlaces_Callback);
    }

    refPlacesService.current.findPlaceFromQuery(request, findPlaceFromQuery_Callback);
  }

  //"useMemo" will 'memoize' or 'cache' the result of this function. 
  //Every time this component re-renders, instead of recalculating this component, the cached result will be returned instead.
  //This prevents the map from re-rending every time the marker location changes, etc.
  const renderMap = useMemo(()=>{
    if (!props.mapReadyToLoad) {
      return (<SkeletonLoader count={6} width="100px"/>)
    }

    const mapContainerStyle = {
      height: 500,
    };

    const mapOptions = {
      center: {
        lat: Number(props.initialLat) || 43.5935084,
        lng: Number(props.initialLng) || -116.2750878
      },
      disableDefaultUI: true,
      fullscreenControl: true,
      gestureHandling: 'cooperative', //user must use two fingers or hit "ctrl" to scroll page (makes accidental map moving less likely)
      mapTypeControl: true, //chose map or satellite at bottom left
      mapTypeControlOptions: {
        position: window?.google?.maps?.ControlPosition?.LEFT_BOTTOM || 6.0,//,
        mapTypeIds: [
          window?.google?.maps?.MapTypeId?.TERRAIN || "terrain",
          window?.google?.maps?.MapTypeId?.HYBRID || "hybrid",
        ],
      },
      mapTypeId: window?.google?.maps?.MapTypeId?.HYBRID || 'hybrid',
      tilt: 0,
      zoom: (!!props.initialLat ? 19 : 10),
      zoomControl: true,
    }

    const autocompleteProps = {
      restrictions: { country: "us" },
      fields: ["address_components", "geometry"],
      types: ["geocode"],
      onPlaceChanged: locationSearch,
      onLoad: onAutocompleteLoad,
    };

    return (
      <GoogleMap
        onLoad={onMapLoad}
        onUnmount={onMapUnmount}
        options={mapOptions}
        mapContainerStyle={mapContainerStyle}
        onCenterChanged={() => {
          if (refMap.current !== null) {
            onMapCenterChanged()
          }
        }}
      >
        <>
          <Col xl={5} md={7} sm={9} xs={9} id="mapTopLeftCtrl">
            <InputGroup className="m-2">
              <Autocomplete {...autocompleteProps} >
                <Form.Control
                  onKeyDown={(event) => {
                    //if user presses enter key, perform search, don't submit form
                    if (event.key === 'Enter') {
                      event.preventDefault();
                    }
                  }}
                  ref={refSearchInput}
                  id="addressSearchInputId"
                  placeholder="Search Address"
                  aria-label="Enter zip code or address here to search"
                  type="text"
                />
              </Autocomplete>
              <Button variant="primary" onClick={() => locationSearch()}><FontAwesomeIcon icon={faMagnifyingGlass} />&nbsp;Find</Button>
              {window.navigator && window.navigator.geolocation && (
                <Button onClick={() => centerMapOnCurrentLocation(true)} variant="primary" title="Go to Current Location"><FontAwesomeIcon icon={faLocationDot} /></Button>
              )}
            </InputGroup>
          </Col>
        </>
      </GoogleMap>
    )
  }, [props.mapReadyToLoad])

  return (
    <>
      {renderMap}
    </>
  )
}
export default React.memo(MapSelector)