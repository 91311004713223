import React, {useState} from "react";
import {Autocomplete} from "@react-google-maps/api";
import SmartTextBox from "./SmartTextBox";
import {formatPlace} from "../../util/functions";

const AddressSearch = (props) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState("");

  const onPlaceChanged = (place) => {
    if(!place) return;
    // Clear out search field since a search result was selected
    setAddress("");
    props.onPlaceChanged(formatPlace(place));
  }

  const autocompleteProps = {
    restrictions: { country: "us" },
    fields: ["address_components", "geometry"],
    types: ["geocode"],
    onPlaceChanged: () => onPlaceChanged(autocomplete?.getPlace()),
    onLoad: (autocomplete) => setAutocomplete(autocomplete),
  }

  return (
    <Autocomplete {...autocompleteProps}>
      <SmartTextBox
        label={props.label}
        maxLength="130"
        value={address}
        onChange={(event) => setAddress(event.target.value)}
        onKeyDown={(event) => {
          // If user presses enter key, perform search, don't submit form
          if (event.key === 'Enter') event.preventDefault();
        }}
      />
    </Autocomplete>
  )
}

export default AddressSearch;