import React from "react";
import $ from "jquery";
import SmartTextBox from "../lib/SmartTextBox";
import SmartDropDownList from "../lib/SmartDropDownList";
import SmartRadioButtons from "../lib/SmartRadioButtons";
import SmartDocumentViewer from "../lib/SmartDocumentViewer";
import SmartSection from "../lib/SmartSection";
import SmartFile from "../lib/SmartFile";
import ApplianceForm from "./ApplianceForm";
import endpoint from "../../util/endpoint";
import {Row, Badge, Form, Col} from "react-bootstrap";
import {getBrand, getSelectedRequest, getLink, getProtectionAreaListFromLocal} from "../../util/functions";
import { wtm } from "../dashboard/TableComponents/SharedConstants";
import _ from 'lodash'
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import AddressSearch from "../lib/AddressSearch";

import cngTrenchPDF from '../../assets/documents/CNGC Trench and meter.pdf'
import cngTrenchMainsPDF from '../../assets/documents/CNGC Trench and meter mains.pdf'
import gpngTrenchPDF from '../../assets/documents/GPNG Trench and meter.pdf'
import gpngTrenchMainsPDF from '../../assets/documents/GPNG Trench and meter mains.pdf'
import igcTrenchPDF from '../../assets/documents/IGC Trench and meter.pdf'
import igcTrenchMainsPDF from '../../assets/documents/IGC Trench and meter mains.pdf'
import igcSnowMeltRequirementsPDF from '../../assets/documents/IGC Snow melt boiler requirements.pdf'
import mduTrenchPDF from '../../assets/documents/MDU Trench and meter.pdf'
import mduApplicationForServiceNDPDF from '../../assets/documents/MDU ND Customer Application for Service.pdf'

class CreateProjectRequest extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoading: true,
    };
  }

  handleInputChange = (event, options = {name: null, value: null }, runServerSave = true) => {
    let name = options.name == null ? event.target.name : options.name;
    let value = options.value == null ? event.target.value : options.value;

    if(runServerSave){
      this.props.updateServiceRequestObject(name, value);
      this.props.saveSR(null, {checkProjectRequestAddressForDuplicate: true});
    }else{
      this.props.updateServiceRequestObject(name, value);
    }
  }

  componentDidMount() {
    $.ajax({
      url: `/api/request/getActiveSR?selected_request_id=${getSelectedRequest()}`,
      dataType: "json",
      method: "GET",
    })
    .then(result => this.props.replaceServiceRequestObject(result))
    .always(() => this.setState({ isLoading: false }));
  }

  checkForSnowLoadZip(newZip) {
    //make sure newZip isn't null
    if(!newZip) return;

    //make sure brand is igc
    if(!getBrand() || (getBrand().toLowerCase() !== "igc")) return;

    //make sure snow load zips are loaded
    let snowLoadProtectionAreas = getProtectionAreaListFromLocal();
    if(!snowLoadProtectionAreas || !Array.isArray(snowLoadProtectionAreas)) return;

    //see if newZip is in snowLoadProtectionAreas
    const isInSnowLoadProtectionArea = snowLoadProtectionAreas?.some(snowLoadProtectionArea => snowLoadProtectionArea.zip === newZip.split('-')[0]);
    if(isInSnowLoadProtectionArea) this.props.updateServiceRequestObject('proInfo.snowLoad', 'YES');
    else this.props.updateServiceRequestObject('proInfo.snowLoad', 'NO');
  }

  handleZipCodeChange = (event, options = {name: null, value: null }, runServerSave = true) => {
    this.handleInputChange(event, options, runServerSave);
    this.checkForSnowLoadZip(event.target.value)
  }

  onPlaceChanged = (place) => {
    if(!place) return; // No result found
    this.props.updateServiceRequestObject('proInfo.tkserviceaddress_streetAddress', place.streetAddress || '');
    this.props.updateServiceRequestObject('proInfo.tkserviceaddress_city', place.serviceCity || '');
    this.props.updateServiceRequestObject('proInfo.tkserviceaddress_stateProvince', place.state || '');
    this.props.updateServiceRequestObject('proInfo.tkserviceaddress_county', place.county || '');
    this.props.updateServiceRequestObject('proInfo.tkserviceaddress_postalCode', place.postalCode || '');
    this.checkForSnowLoadZip(place.postalCode);
    this.props.saveSR(null, {checkProjectRequestAddressForDuplicate: true});
  }

  render() {
    const nextOptions = (event) => {
      event.preventDefault();

      if (!this.formRef.current.checkValidity()) {
        this.formRef.current.classList.add('was-validated');
        return false
      }

      this.props.saveSR_Now(this.props.nextPage, {checkProjectRequestAddressForDuplicate: true});
    };

    const getTrenchAndMeterUrl = () => {
      let brand = getBrand();

      // Use different pdf based on work type
      var mainInstallWorkTypes = [5, 6, 12, 13];
      var isMainInstallType = mainInstallWorkTypes.includes(Number(this.props.selectedWorkType));

      let pdfUrls = {
        "igc": isMainInstallType ? igcTrenchMainsPDF : igcTrenchPDF,
        "mdu": mduTrenchPDF,
        "cng": isMainInstallType ? cngTrenchMainsPDF : cngTrenchPDF,
        "gpg": isMainInstallType ? gpngTrenchMainsPDF : gpngTrenchPDF
      }

      return pdfUrls[brand];
    }

    const getValue = (path) => {
      return _.get(this.props.srObj, path)
    }

    return (
      <form ref={this.formRef} onSubmit={nextOptions} className="customBorder mt-3 needs-validation" noValidate>
        <div className="col-12">
          <h3 className="text-center text-primary mt-3">
            Project Information
          </h3>
          {this.state.isLoading ? <SkeletonLoader count={11} width="100px" /> :
            <Row>
              <SmartSection
                id="addressSection"
                title="Site Address"
              >
                <Row className={"mb-2"}>
                  <Col>
                    <AddressSearch
                      label={<>
                        <div>Search Address (optional)</div>
                        <div className={"fst-italic fw-light"}>Selecting an item from the search result will update the address fields below</div>
                      </>}
                      onPlaceChanged={this.onPlaceChanged}
                    />
                    <hr/>
                  </Col>
                </Row>
                <Row>
                  <SmartTextBox
                    label="Service/911 Street Address"
                    maxLength="130"
                    value={getValue('proInfo.tkserviceaddress_streetAddress')}
                    onChange={(event) => this.handleInputChange(event, {name: "proInfo.tkserviceaddress_streetAddress"})}
                    invalidFeedback="Please provide a valid Street Address."
                    required
                  />
                  <SmartDropDownList
                    label="Service State"
                    value={getValue('proInfo.tkserviceaddress_stateProvince')}
                    onChange={(event) => this.handleInputChange(event, {name: "proInfo.tkserviceaddress_stateProvince"})}
                    invalidFeedback="Please provide a valid State."
                    fetchURL={endpoint.getStates}
                    defaultText='Select Service State'
                    required
                  />
                  <SmartDropDownList
                    label="Service City"
                    value={getValue('proInfo.tkserviceaddress_city')}
                    onChange={(event) => this.handleInputChange(event, {name: "proInfo.tkserviceaddress_city"})}
                    invalidFeedback="Please provide a valid City."
                    fetchURL={endpoint.maximoMDUGCITY + (getValue('proInfo.tkserviceaddress_stateProvince') || 'noStateSelected')}
                    defaultText='Select Service City'
                    required
                    disabled={!getValue('proInfo.tkserviceaddress_stateProvince')}
                  />
                  <SmartTextBox
                    type="zip"
                    label="Service Zip Code"
                    maxLength="10"
                    value={getValue('proInfo.tkserviceaddress_postalCode')}
                    onChange={(event) => this.handleZipCodeChange(event, {name: "proInfo.tkserviceaddress_postalCode"})} //using handleZipCodeChange instead of default function. This handles updating snowload field
                    invalidFeedback="Please provide a valid Zip Code. Valid formats: 12345 or 12345-1234"
                    required
                  />
                  <SmartTextBox
                    label="County"
                    maxLength="36"
                    value={getValue('proInfo.tkserviceaddress_county')}
                    onChange={(event) => this.handleInputChange(event, {name: "proInfo.tkserviceaddress_county"})}
                    workTypeShowList={[1, 2, 3, 4, 14]}
                    workType={this.props.selectedWorkType}
                    invalidFeedback="Please provide a valid County."
                  />
                </Row>
              </SmartSection>
              <SmartTextBox
                label="Development/Subdivision Name"
                maxLength="50"
                value={getValue('proInfo.developmentName')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.developmentName"})}
                workTypeShowList={[
                  wtm.GAS_SERVICE_INSTALL,
                  wtm.GAS_INSTALL_NEW_SUBDIVISION,
                  wtm.ELECTRIC_SERVICE_INSTALL,
                  wtm.ELECTRIC_DEVELOPER_SUBDIVISION,
                  wtm.COMBO_SUBDIVISION,
                  wtm.COMBO_SERVICE_INSTALL,
                ]}
                workType={this.props.selectedWorkType}
                invalidFeedback="Please provide a valid Development/Subdivision Name."
                multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL"]]}
                multipleDeciders={[getValue('cusInfo.serviceType')]}
                required={
                  this.props.selectedWorkType == wtm.GAS_INSTALL_NEW_SUBDIVISION || 
                  (this.props.selectedWorkType == wtm.GAS_SERVICE_INSTALL &&
                  (getValue('cusInfo.serviceType') == "NEWRESIDENTIAL" || getValue('cusInfo.serviceType') == "NEWCOMMERCIAL") &&
                  getValue('proInfo.inNewDevelopment') === "YES")
                }
              />
              <SmartTextBox
                label="Development/Subdivision Phase"
                maxLength="15"
                value={getValue('proInfo.developmentPhase')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.developmentPhase"})}
                workTypeShowList={[
                  wtm.GAS_INSTALL_NEW_SUBDIVISION,
                  wtm.ELECTRIC_DEVELOPER_SUBDIVISION,
                  wtm.COMBO_SUBDIVISION
                ]}
                workType={this.props.selectedWorkType}
                invalidFeedback="Please provide a valid Development/Subdivision Phase."
                multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL"]]}
                multipleDeciders={[getValue('cusInfo.serviceType')]}
                required={
                  this.props.selectedWorkType == wtm.GAS_INSTALL_NEW_SUBDIVISION || 
                  (this.props.selectedWorkType == wtm.GAS_SERVICE_INSTALL &&
                  (getValue('cusInfo.serviceType') == "NEWRESIDENTIAL" || getValue('cusInfo.serviceType') == "NEWCOMMERCIAL") &&
                  getValue('proInfo.inNewDevelopment') === "YES")
                }
              />
              <SmartRadioButtons
                label="Type of Development"
                selectedValue={getValue('proInfo.developmentType')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.developmentType"})}
                invalidFeedback="Please provide a Type of Development."
                fetchURL={"/api/dropdownlist/maximo/MDUGDEVELOPMENTTYP"}
                workTypeShowList={[
                  wtm.GAS_INSTALL_NEW_SUBDIVISION,
                  wtm.ELECTRIC_SERVICE_INSTALL,
                  wtm.ELECTRIC_LIGHT_INSTALL,
                  wtm.ELECTRIC_DEVELOPER_SUBDIVISION,
                  wtm.COMBO_SUBDIVISION
                ]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL"]]}
                multipleDeciders={[getValue('cusInfo.serviceType')]}
                required
              />
              <SmartTextBox
                label="Number of Units"
                type="int"
                maxLength="12"
                value={getValue('proInfo.developmentNumUnits')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.developmentNumUnits"})}
                invalidFeedback="Please provide a valid Number of Units."
                workTypeShowList={[2, 3, 4, 6, 7, 12, 13, 14]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["MIXEDUSE", "MULTIFAMILY"]]}
                multipleDeciders={[getValue('proInfo.developmentType')]}
              />
              <SmartRadioButtons
                label="Is service request in a new development/subdivision?"
                valueList={["YES", "NO"]}
                valueLabelList={["Yes", "No"]}
                selectedValue={getValue('proInfo.inNewDevelopment')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.inNewDevelopment"})}
                invalidFeedback="Please indicate whether this is in a new development/subdivision."
                workTypeShowList={Object.values(wtm).filter((value) => value !== wtm.GAS_INSTALL_NEW_SUBDIVISION)}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL"]]}
                multipleDeciders={[getValue('cusInfo.serviceType')]}
                required
              />
              <SmartTextBox
                id="lot"
                label="Lot"
                maxLength="10"
                workTypeShowList={[1, 2, 3, 4, 5, 7, 8, 9, 11, 14]}
                workType={this.props.selectedWorkType}
                value={getValue('proInfo.tkserviceaddress_mdugLot')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.tkserviceaddress_mdugLot"})}
                invalidFeedback="Please provide a valid Lot."
                multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL", "INDUSTRIAL"]]}
                multipleDeciders={[getValue('cusInfo.serviceType')]}
                required={getValue('proInfo.inNewDevelopment') === "YES"}
              />
              <SmartTextBox
                id="block"
                label="Block"
                maxLength="10"
                value={getValue('proInfo.tkserviceaddress_mdugBlock')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.tkserviceaddress_mdugBlock"})}
                workTypeShowList={[1, 2, 3, 4, 5, 7, 8, 9, 11, 14]}
                workType={this.props.selectedWorkType}
                invalidFeedback="Please provide a valid Block."
                multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL", "INDUSTRIAL"]]}
                multipleDeciders={[getValue('cusInfo.serviceType')]}
                required={getValue('proInfo.inNewDevelopment') === "YES"}
              />
              <SmartRadioButtons
                label="Inside City Limits"
                valueList={["YES", "NO"]}
                valueLabelList={["Yes", "No"]}
                selectedValue={getValue('proInfo.insideCityLimits')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.insideCityLimits"})}
                invalidFeedback="Please provide a valid Inside City Limits."
                required
              />
              <SmartSection
                id="snowLoadSection"
                title="Snow Load Meter Protection"
                workTypeShowList={[2, 3, 4, 7, 14]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["YES"]]}
                multipleDeciders={[getValue('proInfo.snowLoad')]}
              >
                <p style={{fontSize: 'smaller'}}>Protecting gas meters from ice build up and heavy snow accumulation is required in this geographical region. Please read the requirements and then indicate how your meter will be protected.</p>
                
                <SmartDocumentViewer
                  id="meterLocationRequirementsDocumentViewer"
                  headerText="Review the meter location requirements:"
                  label={<>The customer understands the meter location <strong>and snow protection</strong> requirements</>} //snow protection included in this version of this field, but not the lower one
                  documentUrl={getLink("meter-location-guidelines")}
                  documentTitle="Meter Location Requirements"
                  selectedValue={getValue('proInfo.meterLocConfirm')}
                  onChange={(event) => this.handleInputChange(event, {name: "proInfo.meterLocConfirm"})}
                  userPreferenceKey="meterLocationGuidelinesViewExpiryDate"
                  invalidFeedback="Please review and acknowledge that customer understands meter location requirements"
                  workTypeShowList={[2, 3, 4, 7, 9, 14]}
                  workType={this.props.selectedWorkType}
                  multipleShowLists={[["YES"]]} //THIS FIELD IS DISPLAYED DOWN BY THE METER LOCATION FIELDs WHEN proInfo.snowLoad is NO.
                  multipleDeciders={[getValue('proInfo.snowLoad')]}
                  required
                />
                <SmartDropDownList
                  label="Snow Load Meter Protection"
                  value={getValue('proInfo.snowLoadMeterProtection')}
                  onChange={(event) => this.handleInputChange(event, {name: "proInfo.snowLoadMeterProtection"})}
                  invalidFeedback="Please select an option in this dropdown"
                  fetchURL={endpoint.maximoMDUGSNOWMTRPROT}
                  defaultText='Select Snow Load Meter Protection'
                  workTypeShowList={[2, 3, 4, 7, 14]}
                  workType={this.props.selectedWorkType}
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('proInfo.snowLoad')]}
                  required
                />
              </SmartSection>
              
              <SmartRadioButtons
                label="Is Temporary/Construction Heat required?"
                valueList={["YES", "NO"]}
                valueLabelList={["Yes", "No"]}
                selectedValue={getValue('proInfo.tempServiceRequired')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.tempServiceRequired"})}
                invalidFeedback="Please indicate whether Temporary/Construction Heat is required."
                workTypeShowList={[2, 4, 14]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL", "INDUSTRIAL"]]}
                multipleDeciders={[getValue('cusInfo.serviceType')]}
                required
              />
              <SmartTextBox
                label="Temporary Service BTU"
                maxLength="18"
                type="num"
                value={getValue('proInfo.tempServiceBTU')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.tempServiceBTU"})}
                invalidFeedback="Please provide a valid Temp Service BTU."
                workTypeShowList={[2, 4, 14]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["YES"], ["NEWCOMMERCIAL", "NEWRESIDENTIAL", "INDUSTRIAL"]]}
                multipleDeciders={[getValue('proInfo.tempServiceRequired'), getValue('cusInfo.serviceType')]}
                required
              />
              <SmartTextBox
                label="Size of Temporary Construction"
                value={getValue('proInfo.tempServiceSize')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.tempServiceSize"})}
                invalidFeedback="Please provide a valid Size of Temp Construction."
                workTypeShowList={[2, 4, 14]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["YES"], ["NEWCOMMERCIAL", "NEWRESIDENTIAL", "INDUSTRIAL"]]}
                multipleDeciders={[getValue('proInfo.tempServiceRequired'), getValue('cusInfo.serviceType')]}
                required
                maxLength="30"
              />
              <SmartTextBox
                label="Year Home Built"
                maxLength="8"
                value={getValue('proInfo.homeYearBuilt')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.homeYearBuilt"})}
                invalidFeedback="Please provide a valid Year Home Built."
                workTypeShowList={[1, 2, 3, 4, 7, 14]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["cng", "gpg"]]}
                multipleDeciders={[getBrand()]}
                required
              />
              <SmartTextBox
                type="int"
                maxLength="12"
                label="Above ground Sq Footage (Heated)"
                value={getValue('proInfo.homeSqFt')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.homeSqFt"})}
                workTypeShowList={[1, 2, 3, 4, 7, 14]}
                workType={this.props.selectedWorkType}
                invalidFeedback="Please provide a valid Home Sq Footage (Heated)."
                required
              />
              <SmartTextBox
                id="additionSqFootage"
                label="Additional or Below ground Sq Footage"
                type="int"
                maxLength="12"
                value={getValue('proInfo.homeSqFtAdditional')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.homeSqFtAdditional"})}
                invalidFeedback="Please provide a valid Additional Sq Footage."
                workTypeShowList={[2, 3, 4, 7, 14]}
                workType={this.props.selectedWorkType}
                required
              />
              <SmartDropDownList
                id="foundationType"
                label="Foundation Type"
                value={getValue('proInfo.foundationType')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.foundationType"})}
                invalidFeedback="Please provide a Foundation Type."
                fetchURL={endpoint.maximoMDUGFOUNDATIONTYPE}
                defaultText='Select Foundation Type'
                workTypeShowList={[2, 4, 14]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["NEWRESIDENTIAL", "EXISTINGRESIDENTIAL"]]}
                multipleDeciders={[getValue('cusInfo.serviceType')]}
                // required this might need to be required.
              />
              <SmartFile
                label="Plat Map/Site Drawing attachment"
                id="mapAttachment"
                invalidFeedback="Please provide a valid Plat Map/Site Drawing attachment."
                attachmentList={getValue('attachment')}
                maximoFileType="mapAttachment"
              />
              <SmartTextBox
                id="estimatedLengthOfServiceOnProperty"
                label="Estimated service line length(s) (in feet)"
                type="num"
                maxLength="18"
                value={getValue('proInfo.serviceLength')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.serviceLength"})}
                invalidFeedback="Please provide estimated service line length(s) on property."
                workTypeShowList={[1, 2, 3, 4, 6, 7, 9, 12, 13, 14]}
                workType={this.props.selectedWorkType}
              />
              <SmartRadioButtons
                id="trenchConduitCustomerProvided"
                label="Will the trench/conduit be customer provided?"
                fetchURL={"/api/dropdownlist/maximo/MDUGCUSTTRENCH"}
                selectedValue={getValue('proInfo.customerTrench')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.customerTrench"})}
                invalidFeedback="Please indicate whether the trench/conduit is customer provided."
                workTypeShowList={[2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14]}
                workType={this.props.selectedWorkType}
                required
              />
              <SmartDocumentViewer
                id="builderUnderstandsBeddingBackfillRequirements"
                headerText="Review the bedding/backfill requirements:"
                label="Builder understands bedding/backfill requirements"
                documentUrl={getTrenchAndMeterUrl()}
                documentTitle="Trench & Meter"
                selectedValue={getValue('proInfo.customerTrenchConfirm')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.customerTrenchConfirm"})}
                userPreferenceKey="trenchMeterGuidelinesViewExpiryDate"
                invalidFeedback="Please acknowledge that the Builder understands bedding/backfill requirements."
                workTypeShowList={[2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["YES", "UNKNOWN"]]}
                multipleDeciders={[getValue('proInfo.customerTrench')]}
                required
              />
              <SmartDocumentViewer
                id="landscapingRequirementsDocumentViewer"
                headerText="Review the landscaping guidelines:"
                label="Landscape (access) Considerations – Clear vehicle access available?"
                documentUrl={getLink("landscaping-guidelines")}
                documentTitle="Landscaping Guidelines"
                selectedValue={getValue('proInfo.vehicleAccess')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.vehicleAccess"})}
                userPreferenceKey="landscapingGuidelinesViewExpiryDate"
                invalidFeedback="Please review the landscaping guidelines"
                workTypeShowList={[2, 3, 4, 5, 7, 8, 9, 11, 14]}
                workType={this.props.selectedWorkType}
                required
              />
              <SmartDocumentViewer
                id="applicationForServiceNDDocumentViewer"
                headerText="Review the following application:"
                label="North Dakota customer application for service has been reviewed"
                documentUrl={mduApplicationForServiceNDPDF}
                documentTitle="North Dakota Customer Application for Service"
                selectedValue={getValue('proInfo.applicationForServiceNDAcknowledgement')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.applicationForServiceNDAcknowledgement"})}
                userPreferenceKey="applicationForServiceNDAcknowledgement"
                invalidFeedback="Please review the North Dakota customer application for service"
                workTypeShowList={[2, 14]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["ND"]]}
                multipleDeciders={[getValue('proInfo.tkserviceaddress_stateProvince')]}
                required
              />
              <SmartTextBox
                label="Desired Meter Location"
                value={getValue('proInfo.meterLoc')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.meterLoc"})}
                invalidFeedback="Please provide the desired meter location."
                workTypeShowList={[2, 3, 4, 7, 9, 14]}
                workType={this.props.selectedWorkType}
                maxLength="30"
              />
              <SmartRadioButtons
                label="Is Desired Meter Location Marked?"
                valueList={["YES", "NO"]}
                valueLabelList={["Yes", "No"]}
                selectedValue={getValue('proInfo.meterLocMarked')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.meterLocMarked"})}
                invalidFeedback="Please indicate whether the desired meter location is marked."
                workTypeShowList={[2, 3, 4, 7, 9, 14]}
                workType={this.props.selectedWorkType}
              />

              <SmartDocumentViewer
                id="meterLocationRequirementsDocumentViewer"
                headerText="Review the meter location requirements:"
                label="The customer understands the meter location requirements"
                documentUrl={getLink("meter-location-guidelines")}
                documentTitle="Meter Location Requirements"
                selectedValue={getValue('proInfo.meterLocConfirm')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.meterLocConfirm"})}
                userPreferenceKey="meterLocationGuidelinesViewExpiryDate"
                invalidFeedback="Please review and acknowledge that customer understands meter location requirements"
                workTypeShowList={[2, 3, 4, 7, 9, 14]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["NO"]]} //THIS FIELD IS DISPLAYED UP BY THE "Snow Load Meter Protection" FIELD WHEN proInfo.snowLoad is YES.
                multipleDeciders={[getValue('proInfo.snowLoad')]}
                required
              />
              <SmartTextBox
                label="Work Location Comments"
                maxLength="255"
                value={getValue('proInfo.meterLocComments')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.meterLocComments"})}
                invalidFeedback="Please provide a valid Work Location Comments."
                workTypeShowList={[1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14]}
                workType={this.props.selectedWorkType}
              />
              <SmartSection
                id="multiPhaseDevelopmentSection"
                title="Multi-Phase Development"
                workTypeShowList={[6, 12, 13]}
                workType={this.props.selectedWorkType}
              >
                <SmartRadioButtons
                  id="isThisAMultiPhaseDevelopment"
                  label="Is this a multi-phase development?"
                  valueList={["YES", "NO"]}
                  valueLabelList={["Yes", "No"]}
                  selectedValue={getValue('proInfo.dvlpmtMultiPhase')}
                  onChange={(event) => this.handleInputChange(event, {name: "proInfo.dvlpmtMultiPhase"})}
                  invalidFeedback="Please provide a valid  Is this a multi-phase development."
                  workType={this.props.selectedWorkType}
                  required
                />
                <SmartTextBox
                  id="ifYesHowManyPhases"
                  label="Number of phases in development"
                  type="int"
                  maxLength="12"
                  value={getValue('proInfo.dvlpmtNumPhases')}
                  onChange={(event) => this.handleInputChange(event, {name: "proInfo.dvlpmtNumPhases"})}
                  invalidFeedback="Please provide a valid number of phases."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('proInfo.dvlpmtMultiPhase')]}
                  required
                />
                <SmartTextBox
                  id="phaseToBeConstructed"
                  label="Which phase is this request for?"
                  maxLength="12"
                  value={getValue('proInfo.dvlpmtCurrentPhase')}
                  onChange={(event) => this.handleInputChange(event, {name: "proInfo.dvlpmtCurrentPhase"})}
                  invalidFeedback="Please indicate which phase the request is for."
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('proInfo.dvlpmtMultiPhase')]}
                  required
                />
                <SmartTextBox
                  id="totalNumberOfLotsInCurrentPhase"
                  label="Total # of lots in requested phase"
                  type="int"
                  maxLength="12"
                  value={getValue('proInfo.dvlpmtNumLots')}
                  onChange={(event) => this.handleInputChange(event, {name: "proInfo.dvlpmtNumLots"})}
                  invalidFeedback="Please provide the total # of lots in requested phase"
                  multipleShowLists={[["YES"]]}
                  multipleDeciders={[getValue('proInfo.dvlpmtMultiPhase')]}
                  required
                />
              </SmartSection>
              <SmartDropDownList
                id="stageOfConstruction"
                label="Stage of Construction"
                value={getValue('proInfo.dvlpmtStage')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.dvlpmtStage"})}
                invalidFeedback="Please provide a Stage of Construction."
                fetchURL={endpoint.maximoMDUGDVLPMTSTAGE}
                defaultText='Select Stage of Construction'
                workTypeShowList={[2, 7, 14]}
                workType={this.props.selectedWorkType}
                multipleShowLists={[["NEWCOMMERCIAL", "NEWRESIDENTIAL"]]}
                multipleDeciders={[getValue('cusInfo.serviceType')]}
                required
              />
              <SmartTextBox
                id="totalNumberOfLotsInDevelopment"
                label="Total number of lots in all phases"
                type="int"
                maxLength="12"
                value={getValue('proInfo.numLots')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.numLots"})}
                invalidFeedback="Please indicate the total number of lots in all phases."
                workTypeShowList={[3, 6, 12, 13]}
                workType={this.props.selectedWorkType}
                required
              />
              <SmartDropDownList
                id="Plat Status"
                label="Plat Status"
                value={getValue('proInfo.dvlpmtPlatStatus')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.dvlpmtPlatStatus"})}
                invalidFeedback="Please provide a Plat Status."
                fetchURL={endpoint.maximoMDUGPLATSTATUS}
                defaultText='Select Plat Status'
                workTypeShowList={[6, 7, 8, 12, 13, 14]}
                workType={this.props.selectedWorkType}
                required
              />
              <SmartTextBox
                id="estNumberOfServicePointsMetersCurrentPhase"
                label="Estimated number of service points/meters (current phase)"
                type="int"
                maxLength="12"
                value={getValue('proInfo.dvlpmtNumSp')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.dvlpmtNumSp"})}
                invalidFeedback="Please provide a valid Estimated number of service points/meters (current phase)."
                workTypeShowList={[6, 12, 13]}
                workType={this.props.selectedWorkType}
              />
              <SmartTextBox
                id="theAverageSquareFootageOfHomesInDevelopment"
                label="The average square footage of buildings in development"
                type="int"
                maxLength="12"
                value={getValue('proInfo.dvlpmtAvgSqrFt')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.dvlpmtAvgSqrFt"})}
                invalidFeedback="Please provide a valid The average square footage of homes in development."
                workTypeShowList={[6, 13]}
                workType={this.props.selectedWorkType}
              />
              <SmartRadioButtons
                label="Is a snowmelt system being installed?"
                valueList={["YES", "NO"]}
                valueLabelList={["Yes", "No"]}
                selectedValue={getValue('proInfo.isInstallingSnowmeltSystem')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.isInstallingSnowmeltSystem"})}
                invalidFeedback="Please indicate whether this is in a new development."
                multipleShowLists={[["igc"]]}
                multipleDeciders={[getBrand()]}
                required
              />
              <SmartDocumentViewer
                  id="snowMeltRequirementsDocumentViewer"
                  headerText="Review the snow melt requirements:"
                  label="User has read and agrees to the Snow Melt requirements"
                  documentUrl={igcSnowMeltRequirementsPDF}
                  documentTitle="IGC Snow Melt Requirements"
                  selectedValue={getValue('proInfo.igcSnowMeltAcknowledgement')}
                  onChange={(event) => this.handleInputChange(event, {name: "proInfo.igcSnowMeltAcknowledgement"})}
                  userPreferenceKey="igcSnowMeltAcknowledgement"
                  invalidFeedback="Please review the snow melt requirements"
                  multipleShowLists={[["igc"], ["YES"]]}
                  multipleDeciders={[getBrand(), getValue('proInfo.isInstallingSnowmeltSystem')]}
                  required
              />
              <ApplianceForm
                applianceList={getValue('mdugAppliance')}
                onChange={(event) => this.handleInputChange(event, {name: "mdugAppliance"})}
                workType={this.props.selectedWorkType}
                typeLabel="Type"
                descriptionLabel="Description"
                otherLabel="Other Appliance/Equipment"
                btuLabel={[6,12,13].includes(Number(this.props.selectedWorkType))? "Quantity per Premise" : "BTU"}
                workTypeShowList={[2, 3, 4, 6, 13, 14]}
                syncEntries={[
                  {
                    condition: getBrand() === "igc" && getValue('proInfo.isInstallingSnowmeltSystem') == "YES",
                    showOnDropdown: getBrand() === "igc",
                    applianceType: "APPLIANCE CODES - SNOWMELT BOILER",
                    applianceDescription: "83 - SNOWMELT BOILER"
                  }
                ]}
              />
              <Row>
                <div className="col-12">
                  <div className="text-center">
                    <button
                      disabled={this.props.updateInProgress}
                      type="button"
                      onClick={() => this.props.prevPage()}
                      className="btn btn-secondary btn-block text-uppercase mb-2 shadow-sm btn-sm me-2"
                    >
                      Previous
                    </button>
                    <button
                      disabled={this.props.updateInProgress}
                      type="submit"
                      className="btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm"
                    >
                      Next
                    </button>
                  </div>
                  {this.props.updateInProgress && <div className="text-center"><Badge bg="primary" >Saving in progress... </Badge></div>}
                </div>
              </Row>
            </Row>
          }
        </div>
      </form>
    );
  }
}

export default CreateProjectRequest;