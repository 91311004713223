import React from "react";
import $ from "jquery";
import SmartTextBox from "../lib/SmartTextBox";
import MapSelector from "../lib/MapSelector";
import { Row, Badge } from "react-bootstrap";
import {getSelectedRequest} from "../../util/functions";
import _ from 'lodash'
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";

class MapPage extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      isLoading: true,
      serviceRequestObjectLoaded: false,
      mapLocationValidated: false,
    };
  }

  handleInputChange = (event, options = {name: null, value: null }, runServerSave = true) => {
    let name = options.name == null ? event.target.name : options.name;
    let value = options.value == null ? event.target.value : options.value;

    if(runServerSave){
      this.props.updateServiceRequestObject(name, value);
      this.props.saveSR(null, {checkProjectRequestAddressForDuplicate: true});
    }else{
      this.props.updateServiceRequestObject(name, value);
    }
  }

  handleBothCoordinateChange = (coords) => { //used by map
    this.props.updateServiceRequestObject('proInfo.latitudeY', coords.lat.toString().slice(0, 11));
    this.props.updateServiceRequestObject('proInfo.longitudeX', coords.lng.toString().slice(0, 11));
    this.props.saveSR(null, {checkProjectRequestAddressForDuplicate: true});
    this.setState({mapLocationValidated: true});
  }

  componentDidMount() {
    $.ajax({
      url: `/api/request/getActiveSR?selected_request_id=${getSelectedRequest()}`,
      dataType: "json",
      method: "GET",
    })
    .then(result => {
      this.props.replaceServiceRequestObject(result);
      const mapLocationValidated = this.hasLatitudeLongitude(result.proInfo);
      this.setState({
        serviceRequestObjectLoaded: true,
        // Assume map location has been validated if latitude/longitude coordinates have been saved
        // otherwise the map needs to perform a search so page is still loading
        mapLocationValidated: mapLocationValidated,
        isLoading: !mapLocationValidated,
      });
    })
    .catch(() => this.setState({ isLoading: false }));
  }

  onPlaceSearchedInMap = (place) => { //used by map
    this.setState({isLoading: false});
    if(!place) {
      // Google map search failed or did not return a result, force the user to re-verify the pin location
      this.setState({mapLocationValidated: false});
      return;
    }
    this.props.saveSR(null, {checkProjectRequestAddressForDuplicate: true});
  };

  fullAddress() {
    const projectInfo = this.props.srObj.proInfo;
    return projectInfo.tkserviceaddress_streetAddress + " " +
      projectInfo.tkserviceaddress_city + ", " +
      projectInfo.tkserviceaddress_stateProvince + " " +
      projectInfo.tkserviceaddress_postalCode;
  }

  hasLatitudeLongitude = (projectInfo) => {
    projectInfo = projectInfo || this.props.srObj.proInfo;
    return Boolean(projectInfo?.latitudeY && projectInfo.longitudeX);
  }

  render() {
    const nextOptions = (event) => {
      event.preventDefault();

      if (!this.formRef.current.checkValidity()) {
        this.formRef.current.classList.add('was-validated');
        return false
      }

      this.props.saveSR_Now(this.props.nextPage, {checkProjectRequestAddressForDuplicate: true});
    };

    const getValue = (path) => {
      return _.get(this.props.srObj, path)
    }

    return (
      <form ref={this.formRef} onSubmit={nextOptions} className="customBorder mt-3 needs-validation" noValidate>
        <div className="col-12">
          <h3 className="text-center text-primary">
            Site Location
          </h3>
          {this.state.isLoading ? <SkeletonLoader count={3} width="100px"/> :
            <Row>
              <h5 className={"text-center text-secondary mb-3"}>
                {this.fullAddress()}
              </h5>
              {this.state.mapLocationValidated ?
                <h5 className={"text-center"}>
                  Please verify that the map's pin is on the site location.
                  If the pin location is incorrect, place the map's pin on the site location.
                </h5>
                :
                <h5 className={"text-center text-danger"}>
                  The location of the provided site address could not be found. Place the map's pin on the site location.
                </h5>
              }
            </Row>
          }
          <MapSelector
            // Make sure the map doesn't do anything until the data is loaded
            mapReadyToLoad={this.state.serviceRequestObjectLoaded}
            updateCoords={this.handleBothCoordinateChange}
            // Automatically search using the address info from the project info page, unless the latitude/longitude
            // coordinates already exist, then simply initialize the map on those coordinates
            initialAddress={this.hasLatitudeLongitude() ? null : this.fullAddress()}
            initialLat={getValue('proInfo.latitudeY')}
            initialLng={getValue('proInfo.longitudeX')}
            onPlaceSearchedInMap={this.onPlaceSearchedInMap}
          />
          {this.state.isLoading ? <SkeletonLoader count={11} width="100px"/> :
            <Row className={"mt-2"}>
              <SmartTextBox
                type="latlong"
                maxLength="18"
                label="Latitude"
                value={getValue('proInfo.latitudeY')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.latitudeY"})}
                required
                readOnly
              />
              <SmartTextBox
                label="Longitude"
                maxLength="18"
                type="latlong"
                value={getValue('proInfo.longitudeX')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.longitudeX"})}
                required
                readOnly
              />
              <SmartTextBox
                label="Nearest Cross Street to Site Location"
                maxLength="50"
                value={getValue('proInfo.tkserviceaddress_referencePoint')}
                onChange={(event) => this.handleInputChange(event, {name: "proInfo.tkserviceaddress_referencePoint"})}
                invalidFeedback="Please provide a valid Nearest Cross Street."
                workTypeShowList={[1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14]}
                workType={this.props.selectedWorkType}
                required
              />
              <Row>
                <div className="col-12">
                  <div className="text-center">
                    <button
                      disabled={this.props.updateInProgress}
                      type="button"
                      onClick={() => this.props.prevPage()}
                      className="btn btn-secondary btn-block text-uppercase mb-2 shadow-sm btn-sm me-2"
                    >
                      Previous
                    </button>
                    <button
                      disabled={this.props.updateInProgress || !this.state.mapLocationValidated}
                      type="submit"
                      className="btn btn-primary btn-block text-uppercase mb-2 shadow-sm btn-sm"
                    >
                      Next
                    </button>
                  </div>
                  {this.props.updateInProgress && <div className="text-center"><Badge bg="primary">Saving in progress... </Badge></div>}
                </div>
              </Row>
            </Row>
          }
        </div>
      </form>
    );
  }
}

export default MapPage;