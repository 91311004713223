import $ from 'jquery';
import Cookies from "js-cookie";
import _ from 'lodash'

export function getSelectedRequest() {
  return localStorage.getItem('selected_request_id');
}

export function getDateDifferenceInDays(isoString) {
  const currentDate = new Date(); // Current date
  const givenDate = new Date(isoString); // Date from ISO string 
  const timeDifference = givenDate.getTime() - currentDate.getTime(); // Difference in milliseconds 
  const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days 
  return dayDifference;
}

export function getBrand() {
  if(!!Cookies.get('brand-theme')){
    return Cookies.get('brand-theme');
  }

  //if cookie not found (i.e. running locally)
  const BRAND_NAME = {
    'cngc': 'cng',
    'montana-dakota': 'mdu',
    'intgas': 'igc',
    'gpng': 'gpg',
  }

  let brand = BRAND_NAME[window.location.host.split(".")[1]] || 'mdu';
  return brand
}

export function getBrandForHeader() {
  if(!!Cookies.get('header-display-name')){
    return Cookies.get('header-display-name');
  }

  //if cookie not found (i.e. running locally)
  const BRAND_NAME = {
    'cngc': 'CNGC',
    'montana-dakota': 'MDU',
    'intgas': 'IGC',
    'gpng': 'GPNG',
  }

  let brand = BRAND_NAME[window.location.host.split(".")[1]] || 'MDU';
  return brand
}

export function getPasswordStrength(password) {
  let passwordStrength = 0;
  if (!password) return false;
  // if length is 10 characters or more, increase strength value
  if (password.length > 9) {
    passwordStrength += 1; // 1
  }
  // if password contains both lower and uppercase characters, increase strength value
  if (password.match(/([a-z]+.*[A-Z]+)|([A-Z]+.*[a-z]+)/)) {
    passwordStrength += 1; // 2
  }
  // if it has numbers and characters, increase strength value
  if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
    passwordStrength += 1; // 3
  }
  // // if it has one special character, increase strength value
  if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
    passwordStrength += 1; // 4
  }
  // if it has two special characters, increase strength value
  if (
    password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,%,&,@,#,$,^,*,?,_,~])/)
  ) {
    passwordStrength += 1; // 5
  }
  // if it has two numbers characters, increase strength value
  if (password.match(/([0-9]).*([0-9])/)) {
    passwordStrength += 1; // 6
  }
  if (password && password.length > 0 && passwordStrength < 1) {
    passwordStrength += 1; // 1 if only alphabets entered 
  }
  return passwordStrength;
}

export function compareDates(dateString1, dateString2) {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  if (date1 < date2) {
    return -1; // date1 is earlier than date2
  } else if (date1 > date2) {
    return 1; // date1 is later than date2
  } else {
    return 0; // date1 is equal to date2
  }
}

export function objectsAreEqualOrEmpty(obj1, obj2) {
  if(_.isEmpty(obj1) && _.isEmpty(obj2)) return true; //both empty
  return(_.isEqual(obj1, obj2)) //true if equal, false if different (using lodash)
}

export function getBrandSpecificBaseUrl() {
  let shortName = getBrand();
 
  const BRAND_LONG_NAME = {
    'cng': "cngc",
    'mdu': "montana-dakota",
    'igc': "intgas",
    'gpg': "gpng"
  } 
  return "https://www." + BRAND_LONG_NAME[shortName] + ".com";
}

export function getLink(linkName) {
  let brand = getBrand();
  let brandSpecificBaseUrl = getBrandSpecificBaseUrl();
  
  // Links for which the url path is unique for that brand (not counting the brand's specific base url)
  let brandSpecificLinks = {
    "igc": {
    },
    "mdu": {
      "meter-location-guidelines": "/safety-education/gas-meter-location-guidelines/"
    },
    "cng": {
    },
    "gpg": {
      "meter-location-guidelines": "/safety-education/gas-meter-location-guidelines/"
    }
  }

  // Links that are the same regardless brand, or only differ in the brand's base url
  // This link is used when no brand specific link exists
  // (List the full path of the url here)
  let generalLinks = {
    "privacy-policy": "https://www.mdu.com/privacy-policy/",
    "legal-statement": "https://www.mdu.com/legal-statement",
    "meter-location-guidelines": brandSpecificBaseUrl + "/safety-education/meter-location-guidelines/",
    "landscaping-guidelines": brandSpecificBaseUrl + "/safety-education/landscaping-guidelines/"
  }

  let brandSpecificLink = !!brandSpecificLinks[brand][linkName] ? (brandSpecificBaseUrl + brandSpecificLinks[brand][linkName]) : null;
  return brandSpecificLink != null ? brandSpecificLink : generalLinks[linkName];
}

export function getHelpLineNumber() {
  let brand = getBrand();
  let phones = {
    "igc": "800-548-3679",
    "mdu": "800-638-3278",
    "cng": "888-522-1130",
    "gpg": "877-267-4764",
  }
  return phones[brand];
}

export function logout(optionalParams) {
  localStorage.clear();
  $.ajax({
    url: '/logout',
    method: "POST",
  }).always(() => {
    
    let redirectUrl = '/login'
    if(optionalParams){
      try{
        const searchParams = new URLSearchParams(optionalParams)
        redirectUrl += `?${searchParams.toString()}`
      }catch(e) {
        console.log('Failed to parse params for logout function')
      }
    }
    window.location.href=translatedURL(redirectUrl)
  });
  
}

export function getProtectionAreaListFromLocal() {
  try {
    return localStorage.getItem("protectionAreaList") ? JSON.parse(localStorage.getItem("protectionAreaList")) : null;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export function getProtectionAreaList() {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: "/api/request/getProtectionAreaList?brand=" + getBrand(),
      dataType: "JSON",
      success: (result) => {
        localStorage.setItem("protectionAreaList", JSON.stringify(result));
        resolve(result)
      },
      error: (data) => {
        reject(data.responseJSON)
        console.log("getProtectionAreaList err: ", data)
      }
    });
  });
}

export function calculateHolidaysByYear(year) {
  /*
    month index (0 - 11 jan - dec)
    day index (starts at 0)
    name of holiday
  */

  const holidaysExact = [
    { "month": 0, "day": 0, "name": "New Years Day",    showForBrand: {mdu: true, igc: true, cngc: true, gpng: true} },//new years day
    { "month": 6, "day": 3, "name": "Independence Day", showForBrand: {mdu: true, igc: true, cngc: true, gpng: true} },//july 4th
    { "month": 10, "day": 10, "name": "Veteran's Day",  showForBrand: {mdu: true, igc: true, cngc: true, gpng: true} },//veterans day (nov 11)
    { "month": 11, "day": 23, "name": "Christmas Eve",  showForBrand: {mdu: true, igc: true, cngc: true, gpng: true} },//christmas eve
    { "month": 11, "day": 24, "name": "Christmas Day",  showForBrand: {mdu: true, igc: true, cngc: true, gpng: true} },//christmas day
  ]
  /*
    month index, 
    week index (negative number means count from end), 
    weekday index 0-6 Sunday - Saturday
    modifier for day (i.e. "2" would mean add two days to whatever is is figured out.)
    name of holiday
  */
  const holidaysCalc = [
    //thanksgiving day (forth thursday in november)
    { "month": 10, "week": 3, "day": 4, "modifier": 0, "name": "Thanksgiving Day", showForBrand: {mdu: true, igc: true, cng: true, gpg: true} },
    //day after thanksgiving (fourth friday in november)
    { "month": 10, "week": 3, "day": 4, "modifier": 1, "name": "Day After Thanksgiving", showForBrand: {mdu: true, igc: true, cng: true, gpg: true} },
    //president's day (third monday in February)
    { "month": 1, "week": 2, "day": 1, "modifier": 0, "name": "President's Day", showForBrand: {mdu: true, igc: true, cng: true, gpg: true} },
    //martin luther king jr day (third monday of January)
    { "month": 0, "week": 2, "day": 1, "modifier": 0, "name": "Martin Luther King Jr. Day", showForBrand: {mdu: true, igc: true, cng: true, gpg: true} },
    //memorial day (last monday in may)
    { "month": 4, "week": -1, "day": 1, "modifier": 0, "name": "Memorial Day", showForBrand: {mdu: true, igc: true, cng: true, gpg: true} },
    //labor day (first monday in sept)
    { "month": 8, "week": 0, "day": 1, "modifier": 0, "name": "Labor Day", showForBrand: {mdu: true, igc: true, cng: true, gpg: true} },
  ]

  function getExactDate(year, month, day) {
    return new Date(year, month, day + 1)
  }

  function calculateDate(year, month, week, weekDay, modifier) {
    weekDay = weekDay % 7;
    month = month % 11;

    if (week < 0) {
      month++;
      // weekDay += 7;
      //account for partial weeks
      if (new Date(year, month, 0).getDay() >= weekDay) {
        weekDay += 7;
      }
    } else {
      //account for partial weeks
      if (new Date(year, month, 1).getDay() > weekDay) {
        weekDay += 7;
      }
    }
    const date = new Date(year, month, (week * 7) + 1);
    date.setDate(date.getDate() - date.getDay() + weekDay);

    date.setDate(date.getDate() + modifier);
    return date;
  }

  function getActualHolidaysByYear(year) {
    //get all dates (actual dates, not observed dates);
    let holidayDates = [];
    for (const d of holidaysExact) {
      if(!d.showForBrand[getBrand()]) continue;
      holidayDates.push(getExactDate(year, d.month, d.day));
    }
    for (const d of holidaysCalc) {
      if(!d.showForBrand[getBrand()]) continue;
      holidayDates.push(calculateDate(year, d.month, d.week, d.day, d.modifier));
    }

    //sort dates 
    holidayDates.sort((date1, date2) => date1 - date2);

    return holidayDates;
  }

  function convertActualDatesToObservedDates(dates) {
    if (!dates || dates.length === 0) return [];

    //sort
    dates.sort((date1, date2) => date1 - date2);

    //move holidays on saturday to previous day. move holidays on sunday to next day
    for (let i = 0; i < dates.length; i++) {
      const originalDate = new Date(dates[i].getTime());

      let modifier = 0;
      if (dates[i].getDay() === 6) {
        modifier = -1; // for moving day back one
      } else if (dates[i].getDay() === 0) {
        modifier = 1; // for moving day forward one
      }

      //make sure date not the same another holiday
      //move date back as many time as needed
      let done = false;
      let tempIndex = i;

      while (
        modifier !== 0 && (
          dates[i].getTime() === dates[tempIndex].getTime() //date same as other date
          || dates[i].getDay() === 6 //still a weekend
          || dates[i].getDay() === 0 //still a weekend
        )
      ) {
        //get index of date to compare against next
        tempIndex += modifier;
        //loop back to opposite side of list if necessary
        if (tempIndex < 0) tempIndex = dates.length - 1;
        if (tempIndex >= dates.length) tempIndex = 0;

        //move date forward or back
        dates[i].setDate(dates[i].getDate() + modifier);
      }
    }

    dates.sort((date1, date2) => date1 - date2);
    return dates;
  }

  //in order to make sure we get dates from next year that might move back to this year, or last year that move forward to this year, we will include dates from this year, last year, and next year and then filter out all that aren't this year.
  let dateList = getActualHolidaysByYear(year);
  dateList = dateList.concat(getActualHolidaysByYear(year + 1));
  dateList = dateList.concat(getActualHolidaysByYear(year - 1));

  dateList.sort((date1, date2) => date1 - date2);

  let observedList = convertActualDatesToObservedDates(dateList);

  return (observedList.filter(d => d.getFullYear() === year))

}

function holidayCacheByYear(year) {
  const HOLIDAY_KEY = 'holidays';
  const YEAR_STRING = 'year_' + year;

  //get cached holiday dates
  let cache = {};
  if(localStorage.getItem(HOLIDAY_KEY)) cache = JSON.parse(localStorage.getItem(HOLIDAY_KEY));

  //if this year/brand has been cached, return cached values.
  if(cache && cache[YEAR_STRING] && cache[YEAR_STRING][getBrand().toString()]) return cache[YEAR_STRING][getBrand().toString()];

  let observedHolidays = calculateHolidaysByYear(year);

  if(!cache) cache = {};
  if(!cache[YEAR_STRING]) cache[YEAR_STRING] = {};
  cache[YEAR_STRING][getBrand().toString()] = observedHolidays;

  localStorage.setItem(HOLIDAY_KEY, JSON.stringify(cache));

  return observedHolidays;
}

export function isHolidayAndOrWeekend(date){
    //useMemo will cache the holiday list by year and prevent the need to recalculate for every date in the calendar.
    let observedHolidays = holidayCacheByYear(date.getFullYear());
    
    if(date.getDay() == 0 || date.getDay()==6) return true;

    for(let d of observedHolidays) {
        d=new Date(d);
        if(d.getFullYear() === date.getFullYear() 
            && d.getDate() === date.getDate() 
            && d.getMonth() === date.getMonth()
        ){
            return true;
        }
    }
    return false;
}

export function translatedURL(href) {
  let publicUrl = _.clone(process.env.PUBLIC_URL) //clone. don't reference

  if(!publicUrl) publicUrl = '';
  if(!href) href = '#';

  if(href.indexOf('#') !== -1){ //handle anchor urls, etc. 
      return href;
  }

  //make sure public url + href doesn't result in double slashes
  if(publicUrl && publicUrl.charAt(publicUrl.length-1) === "/" && href && href.charAt(0) === "/") {
      publicUrl = publicUrl.slice(0, -1);
  }

  return (publicUrl + href);
}

export function formatPlace(place) {
  if(!place.address_components || !place.geometry) return;

  // Parse address components
  const POSTAL_CODE_TYPE = 'postal_code';
  const STATE_TYPE = 'administrative_area_level_1';
  const COUNTY_TYPE ='administrative_area_level_2';
  const CITY_TYPE ='locality';
  const HOUSE_NUMBER_TYPE ='street_number';
  const STREET_NAME_TYPE ='route';
  const getAddressComponentByType = (a_c_list, type) => a_c_list.find(component => component.types.includes(type));
  const postalCodeComponent = getAddressComponentByType(place.address_components, POSTAL_CODE_TYPE);
  const stateComponent = getAddressComponentByType(place.address_components, STATE_TYPE);
  const countyComponent = getAddressComponentByType(place.address_components, COUNTY_TYPE);
  const cityComponent = getAddressComponentByType(place.address_components, CITY_TYPE);
  const streetAddressPart1Component = getAddressComponentByType(place.address_components, HOUSE_NUMBER_TYPE);
  const streetAddressPart2Component = getAddressComponentByType(place.address_components, STREET_NAME_TYPE);

  // Create formatted result
  const formattedPlace = {};
  formattedPlace.postalCode = postalCodeComponent?.long_name;
  formattedPlace.state = stateComponent?.short_name.toUpperCase().trim();
  formattedPlace.county = countyComponent?.long_name;
  formattedPlace.serviceCity = cityComponent?.long_name.toUpperCase().trim();
  formattedPlace.streetAddress = (streetAddressPart1Component && streetAddressPart2Component) ? `${streetAddressPart1Component.long_name} ${streetAddressPart2Component.long_name}` : '';
  formattedPlace.lat = place.geometry.location.lat();
  formattedPlace.lng = place.geometry.location.lng();

  //as a failsafe, try to parse city and/or service address from place.formatted_address
  if (!place.formatted_address || formattedPlace.serviceCity) {
    //do nothing
  } else if ( !formattedPlace.streetAddress && place.formatted_address.split(",").length > 2 ) {
    //ex: StreetAddress, Bismarck, ND 58501
    formattedPlace.streetAddress = place.formatted_address.split(",")[0].trim();
    formattedPlace.serviceCity = place.formatted_address.split(",")[1].toUpperCase().trim();
  } else if ( place.formatted_address.split(",").length === 2 ) {
    //ex: Boise, ID
    formattedPlace.serviceCity = place.formatted_address.split(",")[0].toUpperCase().trim();
  }

  return formattedPlace;
}