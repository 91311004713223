import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./components/footer/Footer";
import Profile from "./components/profile/Profile";
import EditProfile from "./components/profile/EditProfile";
import EditUser from "./components/profile/EditUser"
import Dashboard from "./components/dashboard/Dashboard";
import MainNav from "./components/header/MainNav"; 
import LoginParent from "./components/login/LoginParent";
import TimeoutHandler from "./components/lib/TimeoutHandler"; 
import ForgotPasswordParent from "./components/forget/ForgotPasswordParent";
import "./assets/css/custom_style.css"
import "./assets/css/cng/cng.css"; 
import "./assets/css/gpg/gpg.css"; 
import "./assets/css/igc/igc.css";
import "./assets/css/mdu/mdu.css";
import { getBrand } from "./util/functions";
import ChangePassword from "./components/profile/ChangePassword";
import CookieConsent from 'react-cookie-consent';
import CacheableFaviconHandler from "./config/FaviconHandler";
import { HelmetProvider } from "react-helmet-async";
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import Login from "./components/login/Login";
import MFA from "./components/login/MFA";
import Register from "./components/register/Register";
import Sim5000_Login from "./components/login/Sim5000_Login";
import {LoadScript} from "@react-google-maps/api";
import LoadingSpinner from "./components/lib/LoadingSpinner";
config.autoAddCss = true

const scriptLoaderProps = {
  id: 'google-map-script',
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
  libraries: ['places'],
  version: "weekly",
  language: "en",
  region: "US",
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: getBrand(),
      profileData: [],
      requestData: [],
      timeoutHandlerShown: false,
      //see comment below around line 62
      // hasToken: Cookies.get("user_logged_in") || false
    };
    this.timeoutHandlerShown = this.timeoutHandlerShown.bind(this);
  }

  timeoutHandlerShown(tf) {
    this.setState({
      timeoutHandlerShown: tf,
    })
  }

  render() {
    return (
      <HelmetProvider>
        <CacheableFaviconHandler brand={this.state.theme} />
      
        <div className={this.state.theme}> 
          <ToastContainer limit={20} />
          <LoadScript {...scriptLoaderProps} loadingElement={<LoadingSpinner show />}>
            <Router basename={process.env.PUBLIC_URL? process.env.PUBLIC_URL : '/'}>
              <Routes>
                <Route exact path='/' element={
                  <>
                    <TimeoutHandler shown={this.state.timeoutHandlerShown} setShown={this.timeoutHandlerShown} />
                    <MainNav />
                    <Dashboard fadeRequestModal={this.state.timeoutHandlerShown} />
                    <Footer />
                  </>
                } />
                <Route exact path='/login' element={ <LoginParent Child={Login} /> } />
                <Route exact path='/sim5000-login' element={ <Sim5000_Login/> } /> {/* This sim5000-login route will be blocked by SpringSecurity when running front and backend together (unless sim5000 mode is true) */}
                <Route exact path="/mfa" element={<LoginParent Child={MFA} />} />
                <Route exact path="/register" element={<LoginParent Child={Register} />} />
                <Route exact path="/forgot-password" element={<ForgotPasswordParent />} />

                <Route exact path='/profile' element={<> <MainNav /> <Profile /> <Footer />  </>} />
                <Route exact path='/editProfile' element={<> <MainNav /> <EditProfile />  <Footer /> </>} />
                <Route exact path='/editUser' element={<> <MainNav /> <EditUser />  <Footer /> </>} />
                <Route exact path='/changePassword' element={ <> <MainNav /> <ChangePassword />  <Footer /> </> }/>
                <Route path='/dashboard' exact element={
                  <>
                    <TimeoutHandler shown={this.state.timeoutHandlerShown} setShown={this.timeoutHandlerShown} />
                    <MainNav />
                    <Dashboard fadeRequestModal={this.state.timeoutHandlerShown} />
                    <Footer />
                  </>
                } />
                <Route exact path='*' element={<> <MainNav /> <p>Page Not Found</p>  <Footer /> </>} />
              </Routes>
            </Router>
          </LoadScript>
          <CookieConsent>The website uses cookies to enhance user experience</CookieConsent>
        </div>
      </HelmetProvider>
    );
  }
}

export default App;